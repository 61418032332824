<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="`${configCharacters}` + $t('key1000394')" prop="ymsProductCategoryId">
              <classificationSelect ref="classificationDom" @selectValue="changeValue"></classificationSelect>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownFilter
              ref="dropDownFilter"
              :selectStyle="{width: '110px'}"
              :dropDownInputList="dropDownInputList"
              :defaultSelectValue="defaultSelectValue"
              inputType="textarea"
              @changeInput="changeInput"
              @updateInputValue="updateInputValue">
            </dropDownFilter>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005638')" prop="goodsDesc">
              <Input :placeholder="$t('key1000192')" clearable v-model.trim="pageParams.goodsDesc"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <div class="filterItem">
              <Form-item :label="$t('key1000395')">
                <local-tabButton :tabButtonList="commodityStatusList" @selectStatus="commodityStatus"></local-tabButton>
              </Form-item>
            </div>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr10">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="inventorySearch" icon="md-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <div class="mr15 sort_options_box">
        <span>{{ $t('key1002427') }}</span>
        <Button-group>
          <Button :type="item.checked ? 'primary' : 'default'"
            v-for="item in sortData"
            @click="clickSortButton(item)"
            :key="item.value">{{ item.label }}
            <Icon type="md-arrow-up" v-if="item.checked && !item.toogle"></Icon>
            <Icon type="md-arrow-down" v-if="item.checked && item.toogle"></Icon>
          </Button>
        </Button-group>
      </div>
      <Button type="primary" class="mr15" @click="$refs.commonImport.importModal = true" icon="md-archive">{{ $t('key1000563') }}</Button>
      <Dropdown @on-click="changeExportBtn" class="mr15" v-if="getPermission('wmsInventory_export')">
        <Button type="primary">
          <span>{{ $t('key1002229') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="1">{{ $t('key1002230') }}</DropdownItem>
          <DropdownItem name="2">{{ $t('key1002231') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Button type="primary" class="mr15" @click="snchronizeInventory">{{ $t('key1000559') }}</Button>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="selection"
        highlight-row
        border
        max-height="490"
        :loading="tableLoading"
        :columns="tableColumns"
        @on-selection-change="tableChange"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        :current="pageParams.pageNum"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--库存事务明细弹窗-->
    <inventoryTransactionDetailsModal
      ref="inventoryTransaction"
      systemType="supplier">
    </inventoryTransactionDetailsModal>
    <!--导入Excel的弹窗-->
    <commonImportModal
      ref="commonImport"
      :importTitle="$t('key1000563')"
      importType="privateWarehouse"
      :uploadAction="importUrl"
      :importOKText="$t('key1000253')"
      fileName="files"
      @uploadSuccess="uploadSuccess"
      downloadText="inventory.xlsx">
    </commonImportModal>
    <!--导出数据字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1005639')"
      paramKeyColums="supplierWmsInventoryColumns"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import {getToken} from "@/utils/cookie";
import commonImportModal from "@/components/common/commonImportModal";
import {SALESSTATUS} from "@/utils/enum";
import dropDownFilter from "@/components/common/dropDownFilter";
import inventoryTransactionDetailsModal from "@/components/common/inventoryTransactionDetailsModal";
import exportFieldsModal from "@/components/common/exportFieldsModal";
import {commonSessionStorage, getBatchCategoryInfoData, pageJump, setConfigCharacters} from "@/utils/common";
import classificationSelect from "@/components/common/classificationSelect.vue";

export default {
  mixins: [Mixin],
  components: {
    classificationSelect,
    commonImportModal,
    dropDownFilter,
    inventoryTransactionDetailsModal, // 库存事务明细组件
    exportFieldsModal
  },
  data() {
    let self = this;
    return {
      importUrl: api.post_wmsInventory_excelImport,
      headObj: {
        Authorization: getToken()
      },
      pageParamsStatus: false,
      cacheClickVal: 'SKU', // 排序缓存值
      wareHouseTypeList: [], // 仓库下拉列表
      // 排序的数据
      sortData: [
        {label: alias1bddc4d174174d47a6dfb0437dace856.t('key1003586'), value: 'SKU', checked: true, toogle: true}
      ],
      pageParams: {
        goodsStatus: null, //  // 0:停售 1:在售 2:试卖 3:清仓
        isInventNumber: 1, // 是否显示库存为0的数据 1显示
        ymsSpus: '', // 云卖SPU
        ymsSkus: '', // 云卖SKU
        supplierSkus: '', // 供应商SKU
        supplierSpus: '', // 供应商SPU
        goodsDesc: '', // 商品描述
        orderSeq: 'DESC', // 降序
        orderBy: 'SKU', // 排序的标记
        pageNum: 1, // 默认第一页
        pageSize: 15, // 规定每页显示 15 条数据
        ymsProductCategoryId: null, // 云卖分类
        queryState: '1', // 标识
        queryParamType: '1',
        warehouseId: this.getWarehouseId()
      },
      total: 0, // 共有多少条
      tableColumns: [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000787'),
          minWidth: 300,
          align: 'center',
          render: (h, params) => {
            let spec = '';
            if (params.row.productGoodsSpecificationLists) {
              spec = params.row.productGoodsSpecificationLists.map(i => i.name + '：' + i.value).join('、');
            }
            let ymsSku = params.row.ymsSku || '';
            return h('div', {
              class: 'flex ptb10'
            }, [
              self.tableImg(h, params, 'goodsUrl'),
              h('div', {
                class: 'text-align-left pl10'
              }, [
                h('p', {class: 'mb5 color-333'}, `${setConfigCharacters}SKU：` + ymsSku),
                (params.row.goodsCnDesc && params.row.goodsCnDesc) || h('p', {
                  attrs: {
                    title: params.row.goodsCnDesc
                  },
                  class: 'text-over-line3 mb5 color-666'
                }, params.row.goodsCnDesc),
                h('p', {
                  class: 'color-green'
                }, spec)
              ])
            ]);
          }
        },
        {
          title: `${setConfigCharacters}SPU`,
          key: 'ymsSpu',
          minWidth: 150,
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000402'),
          key: 'goodsSku',
          minWidth: 150,
          align: 'center',
          render(h, params) {
            let isDel = params.row.goodsIsDelete === 1;
            return h('div', {
              style: {
                padding: '10px'
              }
            }, [
              h('p', {
                style: {
                  textDecoration: (() => {
                    return isDel ? 'line-through' : '';
                  })()
                }
              }, params.row.goodsSku), !isDel || h('p', {style: {color: 'red'}}, alias1bddc4d174174d47a6dfb0437dace856.t('key1005640'))
            ]);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000780'),
          key: 'spu',
          minWidth: 150,
          align: 'center'
        },
        {
          title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1002513')}`,
          key: 'classificationName',
          width: 235,
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000404'),
          key: 'status',
          align: 'center',
          width: 120,
          render: function (h, params) {
            let text = '';
            let status = params.row.goodsStatus;
            self.commodityStatusList.map((item) => {
              if (item.value === status) {
                text = item.title;
              }
            });
            return h('span', text);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000207'),
          key: 'allottedNumber',
          minWidth: 100,
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000208'),
          key: 'availableNumber',
          minWidth: 100,
          align: 'center',
          render(h, params) {
            let currentIndex = params.index;
            let isEdit = params.row.$isEdit;
            let value = params.row.availableNumber;
            let opts = '';
            if (isEdit) {
              let talgValue = '';
              opts = h('div', {
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }
              }, [
                h('Input', {
                  style: {
                    width: '80px',
                    height: '32px'
                  },
                  props: {
                    value: value,
                    autofocus: true
                  },
                  attrs: {
                    class: 'availableInput_' + currentIndex,
                  },
                  on: {
                    input: val => {
                      talgValue = val;
                    },
                    'on-enter': () => {
                      let reg = self.$regular.minPositiveInteger;
                      if (reg.test(Number(talgValue))) {
                        if (talgValue) {
                          self.availableQuantity(talgValue, params.row.productGoodsId, params, true);
                        } else {
                          self.availableQuantity(params.row.availableNumber, params.row.productGoodsId, params, true);
                        }
                      } else {
                        self.$Message.error(alias1bddc4d174174d47a6dfb0437dace856.t('key1000209'));
                      }
                    },
                    'on-blur': () => {
                      let reg = self.$regular.minPositiveInteger;
                      if (reg.test(Number(talgValue))) {
                        if (talgValue) {
                          if (talgValue !== value && !self.enterTalg) {
                            self.availableQuantity(talgValue, params.row.productGoodsId, params, false);
                          }
                        } else {
                          params.row.availableNumber = value;
                          params.row.$isEdit = false;
                          self.enterTalg = false;
                          self.$forceUpdate();
                        }
                      } else {
                        self.$Message.error(alias1bddc4d174174d47a6dfb0437dace856.t('key1000209'));
                      }
                    },
                  }
                }),
                h('Icon', {
                  props: {
                    type: 'md-close-circle',
                    color: '#f00',
                    size: 20
                  },
                  style: {
                    marginLeft: '5px'
                  },
                  class: 'cursor',
                  on: {
                    click: () => {
                      self.$set(params.row, '$isEdit', !isEdit);
                    }
                  }
                })
              ]);
            } else {
              opts = h('a', {
                style: {
                  color: '#2586ED',
                  fontSize: '13px'
                },
                on: {
                  click: () => {
                    setTimeout(() => {
                      self.$nextTick(() => {
                        document.getElementsByClassName('availableInput_' + currentIndex)[0].childNodes[6].focus();
                      });
                    }, 300);
                    self.$set(params.row, '$isEdit', !isEdit);
                  }
                }
              }, value);
            }
            return opts;
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000278'),
          key: 'action',
          align: 'center',
          fixed: 'right',
          width: 100,
          render: function (h, params) {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1003594'),
                    show: self.getPermission('wmsInventoryLog_list'),
                    clickFn: () => {
                      self.$refs['inventoryTransaction'].initFun(params.row);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    self.optionBtnWidth.push(value);
                    params.column.width = Math.max(...self.optionBtnWidth);
                    self.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      tableData: [],
      commodityStatusList: SALESSTATUS,
      dropDownInputList: [
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000402'), value: 1, placeholder: alias1bddc4d174174d47a6dfb0437dace856.t('key1002521'), parameterKey: 'supplierSkus'},
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000780'), value: 2, placeholder: alias1bddc4d174174d47a6dfb0437dace856.t('key1000781'), parameterKey: 'supplierSpus'},
        {title: `${setConfigCharacters}SKU`, value: 3, placeholder: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000287')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1000777')}`, parameterKey: 'ymsSkus'},
        {title: `${setConfigCharacters}SPU`, value: 4, placeholder: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000287')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1000776')}`, parameterKey: 'ymsSpus'},
      ],
      defaultSelectValue: 1,
      selectTableData: [],
      exportType: '1',
      customFieldExportList: [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000596'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'goodsCnDesc', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000785')},
            {paramKey: 'spu', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000780')},
            {paramKey: 'goodsSku', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000402')},
            {paramKey: 'productCategoryNavigation', paramValue: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1002513')}`},
            {paramKey: 'goodsStatus', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000404')},
            {paramKey: 'allottedNumber', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000207')},
            {paramKey: 'availableNumber', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000208')},
          ]
        }
      ],
      enterTalg: false,
    };
  },
  computed: {
    filterKeyList() {
      let parameterKeyList = this.dropDownInputList.map((item) => {
        return item.parameterKey
      });
      return parameterKeyList;
    }
  },
  created() {
    let v = this;
    v.$nextTick(() => {
      v.reset();
    });
    v.getCloudSalesClassification();
    v.searchData();
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.searchData();
        v.pageParamsStatus = false;
      }
    }
  },
  methods: {
    // 获取云卖分类的数据
    getCloudSalesClassification() {
      this.getCloudTreeData(api.post_ymsProductCategory_api_queryAll, 'tile').then((data) => {
        this.$nextTick(() => {
          this.$refs['classificationDom'].initClassificationData(data);
        });
      });
    },
    // 分类筛选
    changeValue(value) {
      this.pageParams.ymsProductCategoryId = value;
    },
    // 选取商品状态
    commodityStatus(index, value) {
      let v = this;
      v.pageParams.goodsStatus = value;
      v.commodityStatusList.map((item, idx) => {
        item.selected = index === idx;
      });
    },
    // 处理参数
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      for (let key in params) {
        if (v.filterKeyList.includes(key)) {
          params[key] = v.sepCommasFn(params[key], ['\n']);
        }
      }
      let obj = commonSessionStorage.getItem('erpConfig');
      params.ymsSiteId = obj ? obj.ymsSite ? obj.ymsSite.ymsSiteId : null : null;
      return params;
    },

    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = [];
      data.map((item) => {
        if (item.productGoodsId) {
          this.selectTableData.push(item.productGoodsId);
        }
      });
    },
    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    changeExportBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1002232'));
          return false;
        }
      } else {
        if (this.tableData.length <= 0) {
          this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1002233'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 导出数据
    exportFieldsDataBtn() {
      let v = this;
      let query = v.handleParameter();
      if (v.exportType === '1') {
        query.productGoodsId = v.selectTableData;
      } else {
        query.productGoodsId = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((value) => {
        if (value) {
          v.axios.post(api.post_wmsInventory_export, query,
            {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // 查询功能
    searchData() {
      var v = this;
      v.tableData = [];
      v.optionBtnWidth = [];
      v.total = 0;
      if (v.getPermission('wmsInventory_list')) {
        v.tableLoading = true;
        v.axios.post(api.post_wmsInventory_list, v.handleParameter()).then(res => {
          if (res.data.code === 0) {
            let data = res.data.datas;
            if (data) {
              let list = data.list || [];
              let ymsProductCategoryIds = [];
              list.map((item) => {
                ymsProductCategoryIds.push(item.ymsProductCategoryId)
              });
              if (ymsProductCategoryIds.length > 0) {
                getBatchCategoryInfoData(api.post_ymsProductCategory_api_queryDetailByCategoryIds, list, ymsProductCategoryIds).then((newData) => {
                  v.tableData = v.handleMultilinguaTableFields(newData, ['goodsCnDesc', 'productCategoryNavigation']);
                  handleData();
                })
              } else {
                v.tableData = v.handleMultilinguaTableFields(list, ['goodsCnDesc', 'productCategoryNavigation']);
                handleData();
              }

              function handleData() {
                if (v.tableData.length > 0) {
                  v.tableData.map((item) => {
                    item['$isEdit'] = false;
                  });
                }
                v.updateTableDom('selection');
              }

              v.total = data.total;
            }
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 页面查询
    inventorySearch() {
      this.pageParams.pageNum = 1;
      this.searchData();
    },
    // 点击排序的按钮
    clickSortButton(data) {
      if (data.value === this.cacheClickVal) {
        data.toogle = !data.toogle;
      }
      this.cacheClickVal = data.value;
      if (data.toogle) {
        this.pageParams.orderSeq = 'DESC';
      } else {
        this.pageParams.orderSeq = 'ASC';
      }
      this.sortData.forEach(function (n) {
        n.checked = data.value === n.value;
      });
      this.pageParams.orderBy = data.value;
      this.searchData();
    },
    // 重置按钮
    reset() {
      this.$refs['pageParams'].resetFields();
      this.commodityStatus(0, null);
      this.changeInput(1);
      this.inventorySearch();
      this.$nextTick(() => {
        this.$refs['classificationDom'].reset();
      })
    },
    // 编辑可用数量
    /**
     *
     * @date: 2022/9/19 11:11
     * @param params 行数据
     */
    availableQuantity(availableNumber, productGoodsId, params, value) {
      let v = this;
      let query = {
        availableNumber: availableNumber,
        productGoodsId: productGoodsId,
        warehouseId: v.pageParams.warehouseId
      };
      v.axios.put(api.put_wmsInventory, query).then(res => {
        if (res.data.code === 0) {
          params.row.availableNumber = availableNumber;
          params.row.$isEdit = false;
          v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000084'));
          v.enterTalg = value;
          if (value) {
            setTimeout(() => {
              v.enterTalg = !value;
            }, 1500);
          }
        }
      });
    },
    // 导入成功的回调
    uploadSuccess() {
      this.pageParamsStatus = true;
    },
    // 同步库存
    snchronizeInventory() {
      let v = this;
      v.axios.post(api.post_wmsInventory_syncJuShuiTanInventory).then(res => {
        if (res.data.code === 0) {
          v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000084'));
          v.pageParamsStatus = true;
        }
      });
    },
    // 改变默认下拉选项的值
    changeInput(value) {
      this.defaultSelectValue = value;
      this.$refs.dropDownFilter.inputValue = '';
      this.dropDownInputList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    },
    // 获取下拉选项筛选条件的值
    updateInputValue(data) {
      this.pageParams[data.key] = data.value;
    },
  }
};
</script>
